import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';

const AboutSection = () => {
  const router = useRouter();
  return (
    <div className="col-lg-3 col-md-3 col-sm-6 col-6 col-xs-12">
      <h6 className="links-title">
        <FormattedMessage id="footer.about.title" defaultMessage="About" />
      </h6>
      <ul className="footer-links">
        <li>
          <Link href={`/${router.locale}/about`} prefetch={false}>
            <a>
              <FormattedMessage
                id="footer.about.about"
                defaultMessage="About Us"
              />
            </a>
          </Link>
        </li>
        <li>
          <Link href={`/${router.locale}/self-storage/faq`} prefetch={false}>
            <a>
              <FormattedMessage id="footer.about.faqs" defaultMessage="FAQs" />
            </a>
          </Link>
        </li>
        <li>
          <Link href={`/${router.locale}/contact-us`} prefetch={false}>
            <a>
              <FormattedMessage
                id="footer.about.contact-us"
                defaultMessage="Contact Us"
              />
            </a>
          </Link>
        </li>
        <li>
          <Link href={`/${router.locale}/offers`} prefetch={false}>
            <a>
              <FormattedMessage
                id="footer.about.deals"
                defaultMessage="Deals"
              />
            </a>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AboutSection;
